<template>
  <div class="myprofile_item myprofile_bodytype">
    <div
      class="myprofile_bodytype_img"
      :style="{ backgroundImage: `url(${profile.bodyType.image})` }"
    ></div>
    <div class="myprofile_item_unit">{{ profile.bodyType.name }}</div>
    <div class="myprofile_item_edit">
      <a class="button_black editOpener" href="#">Edit</a>
      <div class="editContent">
        <CustomSelect :options="options" class="select" @input="onInput" />
      </div>
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import CustomSelect from "@/components/CustomSelect";
import swal from "sweetalert";

export default {
  name: "BodyTypeGrid",
  components: { CustomSelect },
  props: { options: Array },
  data() {
    return {
      isLoading: false,
      selected: {},
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    onInput(selected) {
      this.selected = selected;
      this.save();
    },
    save() {
      swal({
        title: "Warning!",
        text: "You are changing your body shape. This will reset your custom workout schedule and completed exercise log. This change cannot be undone.",
        icon: "warning",
        buttons: ["Cancel", "Proceed"],
        dangerMode: true,
      }).then((willProceed) => {
        if (willProceed) {
          NProgress.start();
          this.isLoading = true;
          this.$store
            .dispatch("user/updateBodyType", {
              bodyTypeId: this.selected.value,
            })
            .catch(() => {
              console.log("error while updating body type");
            })
            .finally(() => {
              NProgress.done();
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>