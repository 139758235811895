import $ from "jquery";

export default function () {
    $(".maincontent").on("click", ".editOpener", function (e) {
        e.preventDefault();
        $(this).hide();
        $(this)
            .closest(".myprofile_item_edit")
            .find(".editContent")
            .fadeIn(200);
    });

    $(".maincontent").on("click", ".plans_ac_opener", function (e) {
        e.preventDefault();
        $(this).toggleClass("opened");
        $(this)
            .closest(".plans_accordion")
            .find(".plan_ac_content")
            .slideToggle(200);
    });
}