<template>
  <div class="myprofile_item myprofile_height">
    <i class="atuicons atuicons-height"></i>
    <div class="myprofile_item_value">{{ height(profile.height).key }}</div>
    <div class="myprofile_item_unit">Height (feet and inches)</div>
    <div class="myprofile_item_edit">
      <a class="button_black editOpener" href="#">Edit</a>
      <div class="editContent">
        <CustomSelect :options="options" class="select" @input="onInput" />
      </div>
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import CustomSelect from "@/components/CustomSelect";
export default {
  name: "HeightGrid",
  components: { CustomSelect },
  data() {
    return {
      isLoading: false,
      selected: {},
      options: [],
    };
  },
  created() {
    this.options = this.$store.getters.settings.selectOptions.user.height;
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    height(value) {
      let selectedElements = this.options.filter((v) => {
        return v.value === value;
      });

      if (selectedElements.length > 0) {
        return selectedElements[0];
      }
    },
    onInput(selected) {
      this.selected = selected;
      this.save();
    },
    save() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("user/updateHeight", {
          height: this.selected.value,
        })
        .catch(() => {
          console.log("error while updating height");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
  },
};
</script>

