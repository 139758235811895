<template>
  <section class="plans_small">
    <div class="wrapper">
      <div class="plans_accordion">
        <div class="plans_ac_opener">
          <i class="atuicons atuicons-plan"></i>Your Workout Plan<span
            class="plan_ac_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="plan_ac_content">
          <div class="plans_list">
            <div
              v-for="option in options"
              :key="option.value"
              :class="{
                plan_item: true,
                plan_selected: selected == option.value,
              }"
            >
              <div class="plan_row">
                <div class="plan_timesbig">{{ option.value }}</div>
                <div class="plan_description">
                  <div class="plan_title">{{ option.key.toUpperCase() }}</div>
                  <div class="plan_times">
                    PLAN <span>{{ option.key.toUpperCase() }}</span>
                  </div>
                </div>
                <div class="plan_status">
                  <i class="atuicons atuicons-tick"></i>
                </div>
              </div>
              <div class="plan_button">
                <a
                  @click="onClick($event, option.value)"
                  class="button button_small button_grey"
                  >{{
                    selected == option.value ? "Your Plan" : "Choose Plan"
                  }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NProgress from "nprogress";
import swal from "sweetalert";

export default {
  name: "Plans",
  props: { plans: Array },
  data() {
    return {
      options: [],
      isLoading: false,
    };
  },
  computed: {
    selected() {
      return this.$store.getters.profile.frequency;
    },
  },
  created() {
    this.options = this.$store.getters.settings.selectOptions.user.frequency;
  },
  methods: {
    onClick(event, frequency) {
      if (this.selected == frequency) {
        return;
      }
      swal({
        title: "Warning!",
        text: "You are changing your workout schedule. This will reset your custom workout schedule and completed exercise log. This change cannot be undone.",
        icon: "warning",
        buttons: ["Cancel", "Proceed"],
        dangerMode: true,
      }).then((willProceed) => {
        if (willProceed) {
          NProgress.start();
          this.isLoading = true;
          this.$store
            .dispatch("user/updateFrequency", { frequency })
            .catch(() => {
              console.log("error while updating frequency");
            })
            .finally(() => {
              this.isLoading = false;
              NProgress.done();
            });
        }
      });
    },
  },
};
</script>
