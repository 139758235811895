<template>
  <div class="myprofile_item myprofile_profile">
    <div
      class="myprofile_photo"
      testkey="testvalue"
      :style="{ backgroundImage: `url(${$store.getters.avatar})` }"
    ></div>
    <div class="myprofile_name">{{ profile.name }}</div>
    <div class="myprofile_about">
      {{ profile.gender }}, {{ ageRange(profile.ageRange).key }}
    </div>

    <div class="myprofile_item_edit">
      <a class="button_black editOpener" href="#">Edit</a>
      <div class="editContent">
        <CustomSelect :options="options" class="select" @input="onInput" />
      </div>
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import CustomSelect from "@/components/CustomSelect";

export default {
  name: "ProfileGrid",
  components: { CustomSelect },
  data() {
    return {
      isLoading: false,
      selected: {},
      options: [],
    };
  },
  created() {
    this.options = this.$store.getters.settings.selectOptions.user.ageRange;
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    ageRange(value) {
      let selectedElements = this.options.filter((v) => {
        return v.value === value;
      });

      if (selectedElements.length > 0) {
        return selectedElements[0];
      }
    },

    onInput(selected) {
      this.selected = selected;
      this.save();
    },

    save() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("user/updateAgeRange", {
          ageRange: this.selected.value,
        })
        .catch(() => {
          console.log("error while updating age range");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
  },
};
</script>


