<template>
  <main class="maincontent">
    <div class="mainh1">
      <div class="wrapper">
        <div class="mainh1_flex">
          <h1>Your Profile</h1>
        </div>
      </div>
    </div>
    <section class="myprofile" v-if="weights.length">
      <div class="wrapper">
        <ProfileMobile v-if="ageRanges.length"></ProfileMobile>
        <div class="myprofile_grid">
          <ProfileGrid v-if="ageRanges.length"></ProfileGrid>
          <MonthGrid></MonthGrid>
          <WeightGrid v-if="weights.length"></WeightGrid>
          <HeightGrid v-if="heights.length"></HeightGrid>
          <BodyTypeGrid
            v-if="bodyTypes.length"
            :options="bodyTypes"
          ></BodyTypeGrid>
        </div>
      </div>
    </section>
    <Plans v-if="frequencies.length"></Plans>
  </main>
</template>

<script>
import NProgress from "nprogress";
import { fetchBodyTypeList } from "@/api/body-type";
import custom_js from "./custom";
import {
  ProfileMobile,
  ProfileGrid,
  MonthGrid,
  WeightGrid,
  HeightGrid,
  BodyTypeGrid,
} from "./components";

import Plans from "@/components/Plans";

export default {
  name: "Profile",
  components: {
    ProfileMobile,
    ProfileGrid,
    MonthGrid,
    WeightGrid,
    HeightGrid,
    BodyTypeGrid,
    Plans,
  },
  data() {
    return {
      frequencies: [],
      heights: [],
      weights: [],
      ageRanges: [],
      bodyTypes: [],
    };
  },

  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    customjs() {
      custom_js();
    },
    getSelectOptions() {
      NProgress.start();
      this.$store
        .dispatch("settings/getSelectOptions")
        .then(() => {
          let { user } = this.$store.getters.settings.selectOptions;
          this.weights = user.weight;
          this.heights = user.height;
          this.ageRanges = user.ageRange;
          this.frequencies = user.frequency;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          NProgress.done();
        });
    },
    getBodyTypeList() {
      fetchBodyTypeList()
        .then(({ data }) => {
          this.prepareBodyTypes(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prepareBodyTypes(data) {
      this.bodyTypes = data.map((value) => {
        return { key: value.name, value: value.id };
      });
    },
  },
  mounted: function () {
    this.customjs();
  },
  created() {
    this.getSelectOptions();
    this.getBodyTypeList();
  },
};
</script>

<style scoped>
@import "~@/assets/css/profile.css";
@import "~@/assets/css/plans.css";
@import "~@/assets/css/bio.css";
</style>

