<template>
  <div class="myprofile_m">
    <div
      class="myprofile_mphoto"
      :style="{ backgroundImage: `url(${$store.getters.avatar})` }"
    ></div>
    <div class="myprofile_mname">{{ profile.name }}</div>
    <div class="myprofile_mabout">
      {{ profile.gender }}, {{ ageRange(profile.ageRange).key }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileMobile",
  data() {
    return {
      options: [],
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  created() {
    this.options = this.$store.getters.settings.selectOptions.user.ageRange;
  },
  methods: {
    ageRange(value) {
      let selectedElements = this.options.filter((v) => {
        return v.value === value;
      });

      if (selectedElements.length > 0) {
        return selectedElements[0];
      }
    },
  },
};
</script>