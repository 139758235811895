<template>
  <div class="myprofile_item myprofile_months">
    <div class="myprofile_months_t">
      Member Since:
      <span>{{ profile.createdOn | moment("MM/DD/YYYY") }}</span>
    </div>
    <div class="myprofile_months_values">
      <div class="myprofile_months_value">
        <span>{{ profile.month }}</span
        >Month
      </div>
      <div class="myprofile_months_value">
        <span>{{ profile.day }}</span
        >day
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "MonthGrid",
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>